import create from '../../_snowpack/pkg/zustand.js';
import produce from '../../_snowpack/pkg/immer.js';

var store = function store(_set, get) {
  return {
    workspace: {
      username: 'Grebban'
    },
    workspaces: [],
    principal: {
      email: 'grebban@info.se'
    },
    user: {
      email: ''
    },
    users: [],
    referrals: [],
    payouts: [],
    loaders: {},
    initializingClient: false,
    loggedIn: false,
    fetchingSession: true,
    loginState: 'login',
    confirmState: 'loading',
    loginService: undefined,
    interfaceTheme: undefined,
    menuOpen: false,
    popOpen: false,
    statusOpen: false,
    tooltip: false,
    tabIndex: 0,
    maxTabIndex: 0,
    galleryResponsiveView: 'desktop',
    snacks: [],
    modal: undefined,
    page: undefined,
    uploading: 0,
    filteredPostsCount: 0,
    chatIsOpen: false,
    sort: {
      code: 'newest',
      name: 'Newest'
    },
    sourceTypes: [{
      type: 'source',
      name: 'Source'
    }, {
      type: 'label',
      name: 'Label'
    }, {
      type: 'product',
      name: 'Product'
    }],
    currentSource: 'all',
    sourcePostsCount: 0,
    sourceCategory: undefined,
    set: function set(fn) {
      return _set(produce(fn));
    }
  };
};

var useStore = create(store);
export { useStore };