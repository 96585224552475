import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2;

import styled, { css } from "../../../_snowpack/pkg/styled-components.js";
import { motion } from "../../../_snowpack/pkg/framer-motion.js";
export var Backdrop = styled(motion.div)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  position: fixed;\n  width: 100%;\n  height: 100%;\n  z-index: calc(", " - 1);\n  top: 0px;\n  left: 0px;\n  right: 0px;\n  bottom: 0px;\n  background-color: rgb(0 0 0 / ", "%);\n  cursor: pointer;\n\n  ", "\n\n  svg {\n    color: var(--text-always-light);\n    z-index: 31;\n    position: absolute;\n    top: var(--spacing-md);\n    right: var(--spacing-md);\n    cursor: pointer;\n  }\n"])), function (props) {
  return props.zindex;
}, function (props) {
  return props.opacity;
}, function (props) {
  return props.$light && css(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n      background-color: rgb(255 255 255 / 12%) !important;\n    "])));
});