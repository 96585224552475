export var camelize = function camelize(str) {
  if (!str) {
    return;
  }

  return str.toLowerCase().replace(/(?:^\w|[A-Z]|\b\w)/g, function (word) {
    return word.toUpperCase();
  });
};
export var lowercase = function lowercase(str) {
  return str.toLowerCase();
};
export var slugify = function slugify(text) {
  var a = 'àáäâèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ·/_,:;';
  var b = 'aaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh------';
  var p = new RegExp(a.split('').join('|'), 'g');
  return text.toString().toLowerCase().replace(/\s+/g, '-') // Replace spaces with -
  .replace(p, function (c) {
    return b.charAt(a.indexOf(c));
  }) // Replace special chars
  .replace(/&/g, '-and-') // Replace & with 'and'
  .replace(/[^\w\-]+/g, '') // Remove all non-word chars
  .replace(/\-\-+/g, '-') // Replace multiple - with single -
  .replace(/^-+/, '') // Trim - from start of text
  .replace(/-+$/, ''); // Trim - from end of text
};
export var lightOrDark = function lightOrDark(color) {
  if (!color) {
    return undefined;
  }

  var r, g, b, hsp;

  if (color.match(/^rgb/)) {
    color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
    r = color[1];
    g = color[2];
    b = color[3];
  } else {
    color = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'));
    r = color >> 16;
    g = color >> 8 & 255;
    b = color & 255;
  }

  hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  if (hsp > 127.5) {
    return 'light';
  } else {
    return 'dark';
  }
};
export var helloPhrase = function helloPhrase() {
  var today = new Date();
  var curHr = today.getHours();

  if (curHr < 4) {
    return 'Working late?';
  } else if (curHr < 7) {
    return 'Early bird catches the worm!';
  } else if (curHr < 12) {
    return 'Good morning';
  } else if (curHr < 17) {
    return 'Good afternoon';
  } else if (curHr < 21) {
    return 'Good evening';
  } else if (curHr < 24) {
    return 'Working late?';
  }
};
export var defaultMouseState = function defaultMouseState(key) {
  switch (key) {
    case 'hover':
      return 'On hover';

    case 'always':
      return 'Always';

    case 'never':
      return 'Never';

    default:
      break;
  }
};
export var getPositionName = function getPositionName(key) {
  switch (key) {
    case 'start':
      return 'Start';

    case 'end':
      return 'End';

    default:
      break;
  }
};
export var galleryType = function galleryType(key) {
  switch (key) {
    case 'FEED':
      return 'Feed';

    case 'COLLAGE':
      return 'Collage';

    case 'SLIDER':
      return 'Slider';

    default:
      break;
  }
};