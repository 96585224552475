import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
var _excluded = ["title", "description", "actions", "size", "to"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { ArrowLeft } from "../../ui/icons.js";
import { Link } from "../../../_snowpack/pkg/react-router-dom.js";
import { PageHeader } from "./PageHeader.style.js";
import PropTypes from "../../../_snowpack/pkg/prop-types.js";
import React from "../../../_snowpack/pkg/react.js";

var PageHeaderComponent = function PageHeaderComponent(_ref) {
  var title = _ref.title,
      description = _ref.description,
      actions = _ref.actions,
      size = _ref.size,
      to = _ref.to,
      props = _objectWithoutProperties(_ref, _excluded);

  return /* @__PURE__ */React.createElement(PageHeader, _objectSpread(_objectSpread({}, props), {}, {
    size: size
  }), /* @__PURE__ */React.createElement("div", {
    className: "page-title"
  }, to ? /* @__PURE__ */React.createElement("div", {
    className: "back"
  }, /* @__PURE__ */React.createElement(Link, {
    to: to
  }, /* @__PURE__ */React.createElement(ArrowLeft, null))) : null, /* @__PURE__ */React.createElement("div", null, /* @__PURE__ */React.createElement("h1", null, title), description && /* @__PURE__ */React.createElement("p", null, description))), /* @__PURE__ */React.createElement("div", {
    className: "page-actions"
  }, actions));
};

PageHeaderComponent.propTypes = {};
PageHeaderComponent.defaultProps = {
  size: "lg"
};
export default PageHeaderComponent;