var easeInOut = [0.65, 0, 0.35, 1];
export var modalCenterAnimation = {
  hidden: {
    opacity: 0,
    x: '-50%',
    scale: 0.98,
    transition: {
      duration: 0.25,
      ease: easeInOut
    }
  },
  show: {
    opacity: 1,
    x: '-50%',
    scale: 1,
    transition: {
      duration: 0.25,
      ease: easeInOut
    }
  },
  exit: {
    opacity: 0,
    x: '-50%',
    scale: 0.98,
    transition: {
      duration: 0.25,
      ease: easeInOut
    }
  }
};
export var modalPageAnimation = {
  hidden: {
    opacity: 0,
    y: 200,
    scale: 0.98,
    transition: {
      duration: 0.25,
      ease: 'easeOut'
    }
  },
  show: {
    opacity: 1,
    y: '0%',
    scale: 1,
    transition: {
      duration: 0.5,
      ease: easeInOut
    }
  },
  exit: {
    opacity: 0,
    y: 200,
    scale: 0.98,
    transition: {
      duration: 0.25,
      ease: 'easeIn'
    }
  }
};
export var modalBgAnimation = {
  hidden: {
    opacity: 0,
    transition: {
      duration: 0.25,
      ease: 'easeOut'
    }
  },
  show: {
    opacity: 1,
    transition: {
      duration: 0.25,
      ease: 'easeInOut'
    }
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.25,
      ease: 'easeIn'
    }
  }
};
export var upgradeParent = {
  show: {
    transition: {
      staggerChildren: 0.05 * 1.25,
      delayChildren: 0.2
    }
  }
};
export var upgradeImage = {
  hidden: {
    opacity: 0,
    scale: 0.7,
    transition: {
      ease: easeInOut,
      duration: 0.5 * 1.25
    }
  },
  show: {
    opacity: 1,
    scale: 1,
    transition: {
      ease: easeInOut,
      duration: 0.5 * 1.25
    }
  }
};
export var upgradeChild = {
  hidden: {
    opacity: 0,
    y: 40,
    transition: {
      ease: easeInOut,
      duration: 0.5 * 1.25
    }
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      ease: easeInOut,
      duration: 0.5 * 1.25
    }
  }
};
export var upgradeLink = {
  hidden: {
    opacity: 0,
    transition: {
      ease: easeInOut,
      duration: 0.5 * 1.25
    }
  },
  show: {
    opacity: 1,
    transition: {
      ease: easeInOut,
      duration: 0.5 * 1.25
    }
  }
};