export var logoAnimation = {
  hidden: {
    opacity: 0,
    scale: 0.5,
    transition: {
      duration: 1,
      ease: 'easeInOut'
    }
  },
  show: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 1,
      ease: 'easeInOut'
    }
  }
};
export var authAnimation = {
  hidden: {},
  show: {
    transition: {
      staggerChildren: 0.15
    }
  }
};
export var loadingAnimation = {
  hidden: {},
  show: {
    transition: {
      staggerChildren: 0.15
    }
  }
};
export var elementAnimation = {
  hidden: {
    opacity: 0,
    y: 30,
    transition: {
      duration: 0.5,
      ease: 'easeInOut'
    }
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
      ease: 'easeInOut'
    }
  }
};