import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2;

import { AnimateSharedLayout, motion } from "../../../_snowpack/pkg/framer-motion.js";
import styled, { css } from "../../../_snowpack/pkg/styled-components.js";
import { AnimatePresence } from "../../../_snowpack/pkg/framer-motion.js";
import { InfoCircle } from "../../ui/icons.js";
import LoaderComponent from "../Loader/Loader.js";
import React from "../../../_snowpack/pkg/react.js";
import ReactDOM from "../../../_snowpack/pkg/react-dom.js";
import SnackComponent from "./Snack.js";
import { useStore } from "../../datalayer/store.js";
export var SnackContainer = styled(motion.div)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  grid-gap: var(--spacing-sm);\n  position: fixed;\n  bottom: var(--spacing-md);\n  right: var(--spacing-md);\n  z-index: var(--z-snack);\n  max-width: 350px;\n  width: 350px;\n\n  ", "\n"])), function (props) {
  return props.$offsetPosition && css(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n      bottom: var(--spacing-xl);\n    "])));
});

var SnackPortal = function SnackPortal(props) {
  var snacks = useStore(function (state) {
    return state.snacks;
  });
  var uploading = useStore(function (state) {
    return state.uploading;
  });
  var sync = useStore(function (state) {
    return state.actions.sync;
  });
  var set = useStore(function (state) {
    return state.set;
  });
  var hasNewInstagramPosts = useStore(function (state) {
    return state.hasNewInstagramPosts;
  });
  var numberOfNewInstagramPosts = useStore(function (state) {
    return state.numberOfNewInstagramPosts;
  });
  var items = /* @__PURE__ */React.createElement(AnimateSharedLayout, null, /* @__PURE__ */React.createElement(SnackContainer, null, snacks === null || snacks === void 0 ? void 0 : snacks.map(function (snack, index) {
    return /* @__PURE__ */React.createElement(SnackComponent, {
      key: "snack-".concat(index),
      type: snack.type,
      title: snack.title,
      message: snack.message,
      duration: snack.duration,
      icon: snack === null || snack === void 0 ? void 0 : snack.icon
    });
  }), /* @__PURE__ */React.createElement(AnimatePresence, {
    exitBeforeEnter: true
  }, uploading ? /* @__PURE__ */React.createElement(SnackComponent, {
    key: "snack-sync",
    title: "Uploading ".concat(uploading, " posts"),
    message: "Your posts are being uploaded as we speak!",
    duration: 1e5,
    icon: /* @__PURE__ */React.createElement(LoaderComponent, null)
  }) : null, hasNewInstagramPosts ? /* @__PURE__ */React.createElement(SnackComponent, {
    key: "snack-new-posts",
    title: "New posts available!",
    message: "You have ".concat(numberOfNewInstagramPosts, " new posts waiting for you."),
    duration: 1e5,
    action: function action() {
      sync.updates();
      set(function (state) {
        state.hasNewInstagramPosts = false;
        state.numberOfNewInstagramPosts = 0;
      });
    },
    actionText: "View posts",
    icon: /* @__PURE__ */React.createElement(InfoCircle, null)
  }) : null)));
  return ReactDOM.createPortal(items, document.getElementById("snack-portal"));
};

export default SnackPortal;