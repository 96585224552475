import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3;

import { Check, Close, InfoCircle, XOctagon } from "../../ui/icons.js";
import React, { useEffect, useState } from "../../../_snowpack/pkg/react.js";
import styled, { css } from "../../../_snowpack/pkg/styled-components.js";
import { AnimatePresence } from "../../../_snowpack/pkg/framer-motion.js";
import ButtonComponent from "../Button/Button.js";
import { motion } from "../../../_snowpack/pkg/framer-motion.js";
import { snackAnimation } from "../../ui/animations/snack.js";
import { useStore } from "../../datalayer/store.js";
export var Snack = styled(motion.div)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  padding: var(--spacing-md);\n  background: var(--snack-background);\n  border-radius: var(--border-radius);\n  display: flex;\n  align-items: flex-start;\n  grid-gap: var(--spacing-md);\n  position: relative;\n  box-shadow: 0px 4px 10px -3px rgba(0, 0, 0, 0.1);\n  overflow: hidden;\n  border: 1px solid var(--border-light);\n\n  .snack-icon {\n    svg {\n      height: 20px;\n      width: 20px;\n      color: var(--primary);\n\n      ", "\n\n      ", "\n    }\n  }\n\n  .snack-details {\n    .snack-title {\n      font-size: var(--font-text);\n      color: var(--text);\n    }\n\n    .snack-message {\n      font-size: var(--font-xs);\n      color: var(--text-opacity);\n      margin-top: var(--spacing-xs);\n    }\n\n    .snack-action {\n      margin-top: var(--spacing-md);\n    }\n  }\n\n  .snack-close {\n    position: absolute;\n    top: var(--spacing-sm);\n    right: var(--spacing-sm);\n    cursor: pointer;\n\n    svg {\n      height: 14px;\n      width: 14px;\n      color: var(--text-opacity);\n    }\n\n    &:hover {\n      opacity: 0.6;\n    }\n  }\n"])), function (props) {
  return props.type === "positive" && css(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n          color: var(--positive) !important;\n        "])));
}, function (props) {
  return props.type === "negative" && css(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n          color: var(--negative);\n        "])));
});

var SnackComponent = function SnackComponent(_ref) {
  var type = _ref.type,
      title = _ref.title,
      message = _ref.message,
      _ref$duration = _ref.duration,
      duration = _ref$duration === void 0 ? 4e3 : _ref$duration,
      icon = _ref.icon,
      action = _ref.action,
      actionText = _ref.actionText;

  var _useState = useState(true),
      _useState2 = _slicedToArray(_useState, 2),
      visible = _useState2[0],
      setVisible = _useState2[1];

  var removeSnack = useStore(function (state) {
    return state.removeSnack;
  });

  var getIconType = function getIconType() {
    switch (type) {
      case "positive":
        return /* @__PURE__ */React.createElement(Check, null);

      case "negative":
        return /* @__PURE__ */React.createElement(XOctagon, null);

      case "primary":
        return /* @__PURE__ */React.createElement(InfoCircle, null);

      default:
        return icon;
    }
  };

  useEffect(function () {
    setTimeout(function () {
      setVisible(false);
    }, duration);
  }, [duration]);
  return /* @__PURE__ */React.createElement(React.Fragment, null, /* @__PURE__ */React.createElement(AnimatePresence, {
    exitBeforeEnter: true
  }, visible && /* @__PURE__ */React.createElement(Snack, {
    layout: true,
    type: type,
    variants: snackAnimation,
    initial: "hidden",
    animate: "default",
    exit: "exit"
  }, /* @__PURE__ */React.createElement("div", {
    className: "snack-icon"
  }, getIconType()), /* @__PURE__ */React.createElement("div", {
    className: "snack-details"
  }, /* @__PURE__ */React.createElement("div", {
    className: "snack-title"
  }, title), /* @__PURE__ */React.createElement("div", {
    className: "snack-message"
  }, message), action ? /* @__PURE__ */React.createElement("div", {
    className: "snack-action"
  }, /* @__PURE__ */React.createElement(ButtonComponent, {
    onClick: action,
    color: "primary",
    size: "small"
  }, actionText)) : null), /* @__PURE__ */React.createElement("div", {
    className: "snack-close",
    onClick: function onClick() {
      return removeSnack();
    }
  }, /* @__PURE__ */React.createElement(Close, null)))));
};

export default SnackComponent;