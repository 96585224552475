import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2;

import { AnimatePresence, motion } from "../../../_snowpack/pkg/framer-motion.js";
import React, { useEffect } from "../../../_snowpack/pkg/react.js";
import { modalBgAnimation, modalCenterAnimation, modalPageAnimation } from "../../ui/animations/modal.js";
import styled, { css } from "../../../_snowpack/pkg/styled-components.js";
import { Backdrop } from "../Backdrop/Backdrop.style.js";
import { Close } from "../../ui/icons.js";
import ReactDOM from "../../../_snowpack/pkg/react-dom.js";
import media from "../../../_snowpack/pkg/styled-media-query.js";
import { useStore } from "../../datalayer/store.js";
export var Modal = styled(motion.div)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  .modal-content {\n    position: fixed;\n    z-index: var(--z-modal);\n    /* overflow: hidden; */\n    top: 13vh;\n    left: 50%;\n    will-change: transform;\n    width: 100%;\n\n    ", "\n  }\n"])), media.greaterThan("medium")(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n      width: auto;\n    "]))));

var ModalContent = function ModalContent(_ref) {
  var closeModal = _ref.closeModal,
      children = _ref.children;
  useEffect(function () {
    var handleEsc = function handleEsc(event) {
      if (event.keyCode === 27) {
        closeModal();
      }
    };

    window.addEventListener("keydown", handleEsc);
    return function () {
      window.removeEventListener("keydown", handleEsc);
    };
  }, []);
  return /* @__PURE__ */React.createElement(React.Fragment, null, children);
};

var ModalPortal = function ModalPortal(props) {
  var modal = useStore(function (state) {
    return state.modal;
  });
  var setModal = useStore(function (state) {
    return state.setModal;
  });

  var closeModal = function closeModal() {
    setModal(void 0);

    if (modal.goBack) {
      window.history.back();
    }
  };

  var modalPortal = /* @__PURE__ */React.createElement(AnimatePresence, null, (modal === null || modal === void 0 ? void 0 : modal.content) && /* @__PURE__ */React.createElement(Modal, {
    key: "modal",
    layout: modal === null || modal === void 0 ? void 0 : modal.layout
  }, /* @__PURE__ */React.createElement(Backdrop, {
    key: "backdrop",
    onClick: function onClick() {
      return closeModal();
    },
    opacity: 60,
    zindex: 40,
    variants: modalBgAnimation,
    initial: "hidden",
    animate: "show",
    exit: "hidden"
  }, /* @__PURE__ */React.createElement(Close, null)), /* @__PURE__ */React.createElement(motion.div, {
    key: "modal-content",
    className: "modal-content",
    variants: (modal === null || modal === void 0 ? void 0 : modal.layout) === "page" ? modalPageAnimation : modalCenterAnimation,
    initial: "hidden",
    animate: "show",
    exit: "hidden"
  }, /* @__PURE__ */React.createElement(ModalContent, {
    closeModal: closeModal
  }, modal === null || modal === void 0 ? void 0 : modal.content))));
  return ReactDOM.createPortal(modalPortal, document.getElementById("modal-portal"));
};

export default ModalPortal;