import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { authActions } from './auth.js';
import { payoutActions } from './payout.js';
import { referralActions } from './referral.js';
import { useStore } from '../store.js';
import { userActions } from './user.js';

var initActions = function initActions() {
  var set = useStore.getState().set;
  var get = useStore.getState;
  return {
    actions: {
      auth: authActions(set, get),
      user: userActions(set, get),
      referral: referralActions(set, get),
      payout: payoutActions(set, get)
    },
    setPopOpen: function setPopOpen(id) {
      set(function (state) {
        state.popOpen = id;
      });
    },
    setStatusOpen: function setStatusOpen(id) {
      set(function (state) {
        state.statusOpen = id;
      });
    },
    setTooltip: function setTooltip(id) {
      set(function (state) {
        state.tooltip = id;
      });
    },
    setLoggedIn: function setLoggedIn() {
      set(function (state) {
        state.loggedIn = true;
      });
    },
    setLoginState: function setLoginState(loginState) {
      set(function (state) {
        state.loginState = loginState;
      });
    },
    setLoginService: function setLoginService(service) {
      set(function (state) {
        state.loginService = service;
      });
    },
    setConfirmState: function setConfirmState(confirmState) {
      set(function (state) {
        state.confirmState = confirmState;
      });
    },
    toggleMenuOpen: function toggleMenuOpen() {
      set(function (state) {
        state.menuOpen = !state.menuOpen;
      });
    },
    setInterfaceTheme: function setInterfaceTheme(theme) {
      set(function (state) {
        state.interfaceTheme = theme;
      });
    },
    getPostById: function getPostById(id) {
      var _get, _get$posts;

      return (_get = get()) === null || _get === void 0 ? void 0 : (_get$posts = _get.posts) === null || _get$posts === void 0 ? void 0 : _get$posts.find(function (post) {
        return post._id === id;
      });
    },
    getCurrentWorkspace: function getCurrentWorkspace() {
      return get().workspaces.find(function (workspace) {
        return workspace.id === get().currentWorkspace;
      });
    },
    setSnack: function setSnack(snack) {
      var message = snack.message;

      if (!message) {
        throw new Error('snack must contain a message');
      }

      set(function (state) {
        state.snacks = [].concat(_toConsumableArray(state.snacks), [snack]);
      });
    },
    removeSnack: function removeSnack(id) {
      set(function (state) {
        var index = state.snacks.findIndex(function (snack) {
          return (snack === null || snack === void 0 ? void 0 : snack.id) === id;
        });
        if (index !== -1) state.snacks.splice(index, 1);
      });
    },
    removeAllSnacks: function removeAllSnacks() {
      set(function (state) {
        state.snacks = [];
      });
    },
    setModal: function setModal(modal) {
      set(function (state) {
        state.modal = modal;
        state.statusOpen = false;
      });
    },
    setPage: function setPage(page) {
      set(function (state) {
        state.page = page;
      });
    }
  };
};

useStore.setState(_objectSpread({}, initActions()));