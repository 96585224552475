import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";
var _excluded = ["source", "size", "type", "fromInstagram", "color"];

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import styled, { css } from "../../../_snowpack/pkg/styled-components.js";
import { Instagram } from "../../ui/icons.js";
import React from "../../../_snowpack/pkg/react.js";
import { lightOrDark } from "../../utils/stringUtils.js";
export var Avatar = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  border-radius: 100%;\n  height: 30px;\n  width: 30px;\n  min-width: 30px;\n  color: ", ";\n\n  ", "\n\n  .instagram-icon {\n    svg {\n      height: 30px;\n      width: 30px;\n      min-width: 30px;\n      color: #fff;\n    }\n  }\n\n  img {\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n  }\n\n  .no-image {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    font-size: var(--font-sm);\n    text-transform: uppercase;\n    height: 100%;\n    font-weight: bold;\n    text-shadow: 0px 0px 5px rgb(0 0 0 / 15%);\n    line-height: 1em;\n  }\n\n  ", "\n\n  ", "\n\n  ", "\n\n    ", "\n"])), function (props) {
  return props.color === "dark" ? "#fff" : "#000";
}, function (props) {
  return !props.$fromInstagram && css(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n      background-color: ", ";\n      overflow: hidden;\n    "])), function (props2) {
    return props2.background;
  });
}, function (props) {
  return props.size === "sm" && css(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n      height: 20px;\n      width: 20px;\n      min-width: 20px;\n\n      .no-image {\n        font-size: var(--font-xs);\n      }\n\n      .instagram-icon {\n        svg {\n          height: 20px;\n          width: 20px;\n          min-width: 20px;\n        }\n      }\n    "])));
}, function (props) {
  return props.size === "lg" && css(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n      height: 75px;\n      width: 75px;\n      min-width: 75px;\n\n      .no-image {\n        font-size: var(--font-lg);\n      }\n\n      .instagram-icon {\n        svg {\n          height: 60px;\n          width: 60px;\n          min-width: 60px;\n        }\n      }\n    "])));
}, function (props) {
  return props.type === "square" && css(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n      border-radius: var(--border-radius);\n    "])));
}, function (props) {
  return props.size === "sm" && props.type === "square" && css(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n      border-radius: var(--border-radius-sm);\n    "])));
});

var AvatarComponent = function AvatarComponent(_ref) {
  var source = _ref.source,
      size = _ref.size,
      type = _ref.type,
      fromInstagram = _ref.fromInstagram,
      color = _ref.color,
      props = _objectWithoutProperties(_ref, _excluded);

  return /* @__PURE__ */React.createElement(React.Fragment, null, (source || fromInstagram) && /* @__PURE__ */React.createElement(Avatar, _objectSpread(_objectSpread({}, props), {}, {
    size: size,
    type: type,
    background: color,
    color: lightOrDark(color),
    $fromInstagram: fromInstagram
  }), fromInstagram ? /* @__PURE__ */React.createElement("div", {
    className: "instagram-icon"
  }, /* @__PURE__ */React.createElement(Instagram, null)) : source && /* @__PURE__ */React.createElement("img", {
    src: source,
    alt: ""
  })));
};

AvatarComponent.propTypes = {};
AvatarComponent.defaultProps = {
  size: "md",
  type: "round"
};
export default AvatarComponent;