import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
var _excluded = ["color", "size", "loading", "type", "children", "expanded"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { Button } from "./Button.style.js";
import Loader from "../Loader/Loader.js";
import PropTypes from "../../../_snowpack/pkg/prop-types.js";
import React from "../../../_snowpack/pkg/react.js";
import { getCookie } from "../../utils/generalUtils.js";
import { useStore } from "../../datalayer/store.js";

var ButtonComponent = function ButtonComponent(_ref) {
  var _ref$color = _ref.color,
      color = _ref$color === void 0 ? "theme" : _ref$color,
      _ref$size = _ref.size,
      size = _ref$size === void 0 ? "medium" : _ref$size,
      loading = _ref.loading,
      type = _ref.type,
      children = _ref.children,
      expanded = _ref.expanded,
      props = _objectWithoutProperties(_ref, _excluded);

  var interfaceTheme = useStore(function (state) {
    return state.interfaceTheme;
  });

  var onClick = function onClick() {
    if (loading) {
      return;
    }

    if (typeof props.onClick === "function") {
      props.onClick();
    }
  };

  var getColor = function getColor() {
    if (color === "theme") {
      return interfaceTheme || getCookie("interfaceColor") || "light";
    }

    return color;
  };

  return /* @__PURE__ */React.createElement(Button, _objectSpread({
    onClick: onClick,
    color: getColor(),
    size: size,
    loading: loading,
    type: type,
    expanded: expanded ? 1 : 0
  }, props), !Boolean(loading) && children, Boolean(loading) && /* @__PURE__ */React.createElement(Loader, null));
};

ButtonComponent.propTypes = {
  color: PropTypes.oneOf(["primary", "dark", "light", "positive", "negative", "theme", "upgrade"]),
  size: PropTypes.oneOf(["small", "medium", "large"]),
  onClick: PropTypes.func
};
ButtonComponent.defaultProps = {
  color: "theme",
  size: "medium",
  onClick: void 0
};
export default ButtonComponent;