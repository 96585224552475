import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";
var _excluded = ["component", "plans"];

var _templateObject;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import React from "../../../_snowpack/pkg/react.js";
import { Route } from "../../../_snowpack/pkg/react-router-dom.js";
import styled from "../../../_snowpack/pkg/styled-components.js";
var _window = window,
    location = _window.location;
export var FullscreenLayout = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  height: 100%;\n  background: var(--background);\n"])));

var FullscreenLayoutComponent = function FullscreenLayoutComponent(_ref) {
  var children = _ref.children;
  return /* @__PURE__ */React.createElement(FullscreenLayout, null, children);
};

var Fullscreen = function Fullscreen(_ref2) {
  var Component = _ref2.component,
      plans = _ref2.plans,
      props = _objectWithoutProperties(_ref2, _excluded);

  return /* @__PURE__ */React.createElement(FullscreenLayoutComponent, null, /* @__PURE__ */React.createElement(Route, _objectSpread(_objectSpread({}, props), {}, {
    component: Component
  })));
};

export default Fullscreen;