import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject;

import styled, { css } from "../../../../_snowpack/pkg/styled-components.js";
import React from "../../../../_snowpack/pkg/react.js";
export var Error = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  color: var(--light);\n  font-size: 12px;\n  position: absolute;\n\n  border-radius: var(--border-radius-sm);\n  background: #e3405e38;\n  color: var(--negative);\n  padding: var(--spacing-xs) var(--spacing-sm);\n  line-height: 12px;\n  right: 10px;\n  top: 50%;\n  transform: translateY(-50%);\n"])));

var ErrorComponent = function ErrorComponent(_ref) {
  var error = _ref.error,
      message = _ref.message;
  return /* @__PURE__ */React.createElement(React.Fragment, null, error && /* @__PURE__ */React.createElement(Error, null, message));
};

export default ErrorComponent;