import { Redirect, Switch } from "../_snowpack/pkg/react-router-dom.js";
import Confirm from "./pages/Auth/Confirm.js";
import FullscreenLayout from "./ui/layouts/FullscreenLayout.js";
import Login from "./pages/Auth/Login.js";
import React from "../_snowpack/pkg/react.js";
import TermsPage from "./pages/Terms.js";

var UnauathentictedApp = function UnauathentictedApp(props) {
  return /* @__PURE__ */React.createElement(Switch, null, /* @__PURE__ */React.createElement(FullscreenLayout, {
    exact: true,
    path: "/",
    component: Login
  }), /* @__PURE__ */React.createElement(FullscreenLayout, {
    exact: true,
    path: "/confirm/:token",
    component: Confirm
  }), /* @__PURE__ */React.createElement(FullscreenLayout, {
    exact: true,
    path: "/partner-terms-agreement",
    component: TermsPage
  }), /* @__PURE__ */React.createElement(Redirect, {
    to: "/"
  }));
};

export default UnauathentictedApp;