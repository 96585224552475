import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12, _templateObject13, _templateObject14, _templateObject15, _templateObject16, _templateObject17, _templateObject18, _templateObject19;

import styled, { css } from "../../../_snowpack/pkg/styled-components.js";
import { motion } from "../../../_snowpack/pkg/framer-motion.js";
export var Button = styled(motion.button)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  color: var(--button-text-light);\n  padding: 10px 15px;\n  border-radius: var(--border-radius);\n  font-size: 14px;\n  text-align: center;\n  cursor: pointer;\n  font-weight: 400;\n  outline: none;\n  display: inline-flex;\n  align-items: center;\n  justify-content: center;\n  transition: 0.15s background-color var(--transition),\n    0.15s border var(--transition);\n  user-select: none;\n  background: none;\n  white-space: nowrap;\n\n  svg {\n    height: 16px;\n    width: 16px;\n\n    &:first-child:not(:last-child) {\n      margin-right: 8px;\n    }\n\n    &:last-child:not(:first-child) {\n      margin-left: 8px;\n    }\n  }\n\n  // Colors -------------------------------------\n\n  ", "\n\n  ", "\n\n    \n\n  ", "\n\n    \n    ", "\n    \n\n  ", "\n\n    ", "\n    \n  // Plain -------------------------------------\n\n  ", "\n\n  ", "\n\n    ", "\n\n    // Special events -------------------------------------\n\n    ", "\n\n    ", "\n\n    ", "\n\n      ", "\n"])), function (props) {
  return props.color === "primary" && css(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n      background-color: var(--primary);\n      border: 1px solid var(--primary);\n\n      &:hover {\n        background-color: var(--primary-hover);\n        border: 1px solid var(--primary-hover);\n      }\n\n      &:active {\n        background-color: var(--primary-active);\n        border: 1px solid var(--primary-hover);\n      }\n    "])));
}, function (props) {
  return props.color === "positive" && css(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n      background-color: var(--positive);\n      border: 1px solid var(--positive);\n      &:hover {\n        background-color: var(--positive-hover);\n        border: 1px solid var(--positive-hover);\n      }\n      &:active {\n        background-color: var(--positive-active);\n        border: 1px solid var(--positive-hover);\n      }\n    "])));
}, function (props) {
  return props.color === "negative" && css(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n      background-color: var(--negative);\n      border: 1px solid var(--negative);\n      &:hover {\n        background-color: var(--negative-hover);\n        border: 1px solid var(--negative-hover);\n      }\n      &:active {\n        background-color: var(--negative-active);\n        border: 1px solid var(--negative-hover);\n      }\n    "])));
}, function (props) {
  return props.color === "light" && css(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n      background-color: var(--light);\n      border: 1px solid var(--light-button-border);\n      color: var(--button-text-dark);\n\n      svg {\n        color: var(--button-text-dark);\n      }\n\n      &:hover {\n        background-color: var(--light-hover);\n        border: 1px solid var(--light-button-border);\n      }\n\n      &:active {\n        background-color: var(--light-active);\n        border: 1px solid var(--light-button-border);\n      }\n    "])));
}, function (props) {
  return props.color === "dark" && css(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n      background-color: var(--dark);\n      border: 1px solid var(--dark);\n\n      svg {\n        color: var(--button-text-light);\n      }\n\n      &:hover {\n        background-color: var(--dark-hover);\n        border: 1px solid var(--dark-hover);\n      }\n\n      &:active {\n        background-color: var(--dark-active);\n        border: 1px solid var(--dark-active);\n      }\n    "])));
}, function (props) {
  return props.color === "upgrade" && css(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n      border: 1px solid var(--primary-light);\n      color: var(--primary-light);\n\n      svg {\n        color: var(--primary-light);\n      }\n\n      &:hover {\n        border: 1px solid var(--primary-light);\n      }\n\n      &:active {\n        border: 1px solid var(--primary-light);\n      }\n    "])));
}, function (props) {
  return props.type === "plain" && css(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n      padding: 0px;\n      background: none !important;\n      border: none !important;\n\n      &:hover {\n        background: none;\n      }\n\n      &:active {\n        background: none;\n      }\n\n      ", "\n\n      ", "\n\n      ", "\n      ", "\n\n      ", "\n    "])), function (props2) {
    return props2.color === "primary" && css(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n          color: var(--primary);\n\n          &:hover {\n            color: var(--primary-hover);\n          }\n\n          &:active {\n            color: var(--primary-active);\n          }\n        "])));
  }, function (props2) {
    return props2.color === "positive" && css(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n          color: var(--positive);\n          &:hover {\n            color: var(--positive-hover);\n          }\n\n          &:active {\n            color: var(--positive-active);\n          }\n        "])));
  }, function (props2) {
    return props2.color === "negative" && css(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n          color: var(--negative);\n\n          &:hover {\n            color: var(--negative-hover);\n          }\n\n          &:active {\n            color: var(--negative-active);\n          }\n        "])));
  }, function (props2) {
    return props2.color === "light" && css(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["\n          color: var(--light-button-text);\n\n          &:hover {\n            color: var(--light-hover);\n          }\n\n          &:active {\n            color: var(--light-active);\n          }\n        "])));
  }, function (props2) {
    return props2.color === "dark" && css(_templateObject13 || (_templateObject13 = _taggedTemplateLiteral(["\n          color: var(--dark);\n\n          &:hover {\n            color: var(--dark-hover);\n          }\n\n          &:active {\n            color: var(--dark-active);\n          }\n        "])));
  });
}, function (props) {
  return props.size === "small" && css(_templateObject14 || (_templateObject14 = _taggedTemplateLiteral(["\n      padding: 4px 6px;\n      border-radius: var(--border-radius-sm);\n      font-size: 12px;\n\n      svg {\n        height: 14px !important;\n        width: 14px !important;\n      }\n    "])));
}, function (props) {
  return props.size === "large" && css(_templateObject15 || (_templateObject15 = _taggedTemplateLiteral(["\n      padding: 14px 20px;\n      border-radius: 8px;\n      font-size: 18px;\n\n      svg {\n        height: 20px !important;\n        width: 20px !important;\n      }\n    "])));
}, function (_ref) {
  var disabled = _ref.disabled;
  return disabled && css(_templateObject16 || (_templateObject16 = _taggedTemplateLiteral(["\n      opacity: 0.5;\n      pointer-events: none;\n    "])));
}, function (_ref2) {
  var loading = _ref2.loading;
  return loading && css(_templateObject17 || (_templateObject17 = _taggedTemplateLiteral(["\n      cursor: default;\n      pointer-events: none;\n    "])));
}, function (props) {
  return props.expanded && css(_templateObject18 || (_templateObject18 = _taggedTemplateLiteral(["\n      width: 100%;\n    "])));
}, function (props) {
  return props.noEvents && css(_templateObject19 || (_templateObject19 = _taggedTemplateLiteral(["\n      pointer-events: none;\n    "])));
});