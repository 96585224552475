import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject;

import styled, { css } from "../../../_snowpack/pkg/styled-components.js";
import React from "../../../_snowpack/pkg/react.js";
import { motion } from "../../../_snowpack/pkg/framer-motion.js";
import { randomInt } from "../../utils/generalUtils.js";
export var RandomGif = styled(motion.div)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  img {\n    border-radius: var(--border-radius);\n    max-height: 250px;\n  }\n"])));

var RandomGifComponent = function RandomGifComponent() {
  var gifs = ["dwight", "technical-issue", "facepalm", "mega-facepalm"];
  return /* @__PURE__ */React.createElement(RandomGif, null, /* @__PURE__ */React.createElement("img", {
    src: "/images/gifs/".concat(gifs[randomInt(4)], ".gif"),
    alt: "There was an issue"
  }));
};

export default RandomGifComponent;