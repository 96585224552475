import React, { useEffect } from "../../../_snowpack/pkg/react.js";
import { appendMeta, appendScript, removeMeta, removeScript } from "../../utils/generalUtils.js";
import { googleClientId } from "../../config.js";
var META_NAME = "google-signin-client_id";
var META_CONTENT = googleClientId;
var SCRIPT_URL = "https://apis.google.com/js/platform.js?onload=triggerGoogleLoaded";

var GoogleScriptComponent = function GoogleScriptComponent() {
  useEffect(function () {
    window.triggerGoogleLoaded = function () {
      gapi.load("auth2", function () {
        gapi.auth2.init();
      });
    };

    appendScript(SCRIPT_URL);
    appendMeta(META_NAME, META_CONTENT);
    return function () {
      removeScript(SCRIPT_URL);
      removeMeta(META_NAME);
    };
  }, []);
  return null;
};

export default GoogleScriptComponent;