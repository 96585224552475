export var snackAnimation = {
  hidden: {
    opacity: 0,
    y: 30,
    transition: {
      duration: 0.25,
      ease: 'easeOut'
    }
  },
  "default": {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.25,
      ease: 'easeInOut'
    }
  },
  exit: {
    opacity: 0,
    y: -30,
    transition: {
      duration: 0.25,
      ease: 'easeIn'
    }
  }
};