
      function getAbsoluteUrl(relativeUrl) {
        const publicPath = __webpack_public_path__;
        let url = '';
        if (!publicPath || publicPath.indexOf('://') < 0) {
          url += window.location.protocol + '//' + window.location.host;
        }
        if (publicPath) {
          url += publicPath;
        } else {
          url += '/';
        }
        return url + relativeUrl;
      }
import * as __SNOWPACK_ENV__ from '../_snowpack/env.js';
({ url: getAbsoluteUrl('dist/index.js'), env: __SNOWPACK_ENV__ }).env = __SNOWPACK_ENV__;
import "./datalayer/index.js";
import React, { useEffect } from "../_snowpack/pkg/react.js";
import { BrowserRouter } from "../_snowpack/pkg/react-router-dom.js";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary.js";
import { GlobalStyle } from "./ui/global.js";
import ModalPortal from "./components/ModalPortal/ModalPortal.js";
import { QueryClientProvider } from "../_snowpack/pkg/react-query.js";
import ReactDOM from "../_snowpack/pkg/react-dom.js";
import SnackPortal from "./components/SnackPortal/SnackPortal.js";
import UnauthenticatedApp from "./unauthenticated-app.js";
import { cache } from "./datalayer/api/client.js";
import { useStore } from "./datalayer/store.js";
var AuthenticatedApp = React.lazy(function () {
  return import("./authenticated-app.js");
});
var AdminApp = React.lazy(function () {
  return import("./admin-app.js");
});

var App = function App(props) {
  var auth = useStore(function (state) {
    return state.actions.auth;
  });
  var loggedIn = useStore(function (state) {
    return state.loggedIn;
  });
  var fetchingSession = useStore(function (state) {
    return state.fetchingSession;
  });
  var user = useStore(function (state) {
    return state.user;
  });

  var getApp = function getApp() {
    if (fetchingSession) {
      return /* @__PURE__ */React.createElement("div", null);
    }

    if (!loggedIn) {
      return /* @__PURE__ */React.createElement(UnauthenticatedApp, null);
    }

    if (user !== null && user !== void 0 && user.isAdmin) {
      return /* @__PURE__ */React.createElement(AdminApp, null);
    }

    return /* @__PURE__ */React.createElement(AuthenticatedApp, null);
  };

  useEffect(function () {
    auth.getSession();
  }, []);
  return /* @__PURE__ */React.createElement(React.Suspense, {
    fallback: /* @__PURE__ */React.createElement("div", null)
  }, /* @__PURE__ */React.createElement(ErrorBoundary, null, getApp()));
};

ReactDOM.render( /* @__PURE__ */React.createElement(BrowserRouter, null, /* @__PURE__ */React.createElement(QueryClientProvider, {
  client: cache
}, /* @__PURE__ */React.createElement(GlobalStyle, null), /* @__PURE__ */React.createElement(App, null), /* @__PURE__ */React.createElement(SnackPortal, null), /* @__PURE__ */React.createElement(ModalPortal, null))), document.getElementById("root"));

if (undefined
/* [snowpack] ({ url: getAbsoluteUrl('dist/index.js'), env: __SNOWPACK_ENV__ }).hot */
) {
  undefined
  /* [snowpack] ({ url: getAbsoluteUrl('dist/index.js'), env: __SNOWPACK_ENV__ }).hot */
  .accept();
}