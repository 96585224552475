import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

var _templateObject;

import Page from "../components/Page/Page.js";
import PageHeader from "../components/PageHeader/PageHeader.js";
import React from "../../_snowpack/pkg/react.js";
import { ReactTitle } from "../../_snowpack/pkg/react-meta-tags.js";
import styled from "../../_snowpack/pkg/styled-components.js";
var Style = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  p {\n    .header {\n      color: var(--text);\n      margin: var(--spacing-md) 0;\n      margin-top: var(--spacing-lg);\n      font-weight: bold;\n      font-size: var(--font-sm);\n    }\n\n    color: var(--text-opacity);\n  }\n"])));

var TermsPage = function TermsPage(props) {
  return /* @__PURE__ */React.createElement(Page, {
    layout: "narrow"
  }, /* @__PURE__ */React.createElement(ReactTitle, {
    title: "Partner Terms Agreement"
  }), /* @__PURE__ */React.createElement(PageHeader, {
    title: "Partner Terms Agreement"
  }), /* @__PURE__ */React.createElement(Style, null, /* @__PURE__ */React.createElement("p", null, "Thank you for considering the Cevoid program. Our partners are very important to us. This agreement is designed with you in mind, as well as protecting you, Cevoid and our customers. Please read thoroughly. If you have any questions, please contact us at info@cevoid.com .", " ", /* @__PURE__ */React.createElement("div", {
    className: "header"
  }, "Partner Agreement"), 'This agreement describes the terms and conditions for participation in the Cevoid Partner program. In this agreement, the term "Partner" refers to you (the applicant). In this agreement, “Cevoid” refers to Cevoid AB, with whom you are entering this agreement. By applying to the Cevoid Partner program you are confirming that you have read the agreement and agree to the terms and conditions.', /* @__PURE__ */React.createElement("div", {
    className: "header"
  }, "Commissions"), "For a sale to generate a commission to a Partner, the Customer must complete an in-app purchase and remit full payment for the product. Commissions will only be paid on sales that are made when the Customer clicks through qualified, correctly structured Partner links. Properly coded links are the sole responsibility of the partner. Commissions can be viewed on the Partner Dashboard.", /* @__PURE__ */React.createElement("br", null), /* @__PURE__ */React.createElement("br", null), " All commissions are on a recurring payment schedule, and will continue to accumulate commissions for each paid transaction by the Customer for as long as the Customer remains a paying Customer. Commissions will also accumulate for plan upgrades and be adjusted for plan downgrades.", /* @__PURE__ */React.createElement("div", {
    className: "header"
  }, "Payment"), "Payments will be paid out monthly for the balance of earnings. Payments will generally be paid out via wire transfer.", /* @__PURE__ */React.createElement("div", {
    className: "header"
  }, "Refunds"), "In the event a Customer requests a refund for a transaction for which the Partner has earned commissions, any commissions earned on the refund amount will be deducted from the Partner’s balance.", " ", /* @__PURE__ */React.createElement("div", {
    className: "header"
  }, "Usage and Obligations"), "Partners are permitted to use the Cevoid brand and marketing resources available in the Partners section of the Cevoid application. Logos and other assets cannot be modified. The Partner does not gain any trademark, copyright or any other rights to these materials.", /* @__PURE__ */React.createElement("br", null), /* @__PURE__ */React.createElement("br", null), " The Partner will never imply that they are acting on behalf of Cevoid and will never advertise Cevoid products directly. The Partner will never bid for advertisements that compete with Cevoid.The Partner will never represent themselves, Cevoid or their relationship with Cevoid in a false or misleading way. The Partner will not engage in the distribution of an unsolicited bulk email (spam) mentioning or referencing Cevoid.", /* @__PURE__ */React.createElement("div", {
    className: "header"
  }, "Term and Termination"), "Either party has the right to terminate the agreement immediately without prior notice.", /* @__PURE__ */React.createElement("br", null), /* @__PURE__ */React.createElement("br", null), " If the Partner terminates the agreement, no further commissions from Cevoid will be paid for any past or future Customer transactions. If Cevoid chooses to terminate the agreement, any balance greater than $25 USD will be paid to the Partner within 60 days of termination. Balances that are smaller than $25 will be forfeited.", /* @__PURE__ */React.createElement("br", null), /* @__PURE__ */React.createElement("br", null), " Cevoid will not be obligated for any future payments after termination.", /* @__PURE__ */React.createElement("div", {
    className: "header"
  }, "Personal Data"), "Cevoid is not obliged to share with Partner any of the personal data of the Customer (or any natural persons representing the Customers or providing services or work for them) who have concluded a Client Agreement with Cevoid. If the Partner wishes to keep personal data concerning Customer for its own internal use, the Partner must obtain prior consent from the respective Referred Clients directly (or any natural person representing the Clients or providing services or work for them).", /* @__PURE__ */React.createElement("div", {
    className: "header"
  }, "Governing Law"), "This Agreement shall be governed, construed, and enforced in accordance with the laws in the country of Sweden, without regard to its conflict of laws rules.", /* @__PURE__ */React.createElement("div", {
    className: "header"
  }, "Governing Law"), "All claims and disputes arising under or relating to this Agreement are to be settled by Arbitration Rules of the Arbitration Institute of the Stockholm Chamber of Commerce. An award of arbitration may be confirmed in a court of competent jurisdiction.", /* @__PURE__ */React.createElement("div", {
    className: "header"
  }, "Modification"), "We may modify any of the terms and conditions within this Agreement at any time and at our sole discretion. These modifications may include, but not limited to changes in the scope of available commissions, commission schedules, payment procedures and Partner Program rules.", /* @__PURE__ */React.createElement("br", null), /* @__PURE__ */React.createElement("br", null), /* @__PURE__ */React.createElement("br", null), /* @__PURE__ */React.createElement("br", null), "Last updated: 23th August 2021.")));
};

export default TermsPage;