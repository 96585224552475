import { A as Ae } from './common/styled-components.browser.esm-b3a349cc.js';
import './common/hoist-non-react-statics.cjs-3cd3243a.js';
import './common/_commonjsHelpers-798ad6a7.js';
import './common/index-404563d3.js';

function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }

  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n          @media (min-width: ", ") {\n            ", "\n          }\n        "]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n          @media (max-width: ", ") {\n            ", "\n          }\n        "]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n    @media (min-width: ", ") and\n      (max-width: ", ") {\n      ", "\n    }\n  "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n    @media (min-width: ", ") {\n      ", "\n    }\n  "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n    @media (max-width: ", ") {\n      ", "\n    }\n  "]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}
/**
 * Default media breakpoints
 * @type {Object}
 */


var defaultBreakpoints = {
  huge: '1440px',
  large: '1170px',
  medium: '768px',
  small: '450px'
};

function getSizeFromBreakpoint(breakpointValue) {
  var breakpoints = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

  if (breakpoints[breakpointValue]) {
    return breakpoints[breakpointValue];
  } else if (parseInt(breakpointValue)) {
    return breakpointValue;
  } else {
    console.error('styled-media-query: No valid breakpoint or size specified for media.');
    return '0';
  }
}
/**
 * Media query generator
 * @param {Object} breakpoints - Map labels to breakpoint sizes
 * @return {Object} - Media generators for each breakpoint
 */


function generateMedia() {
  var breakpoints = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : defaultBreakpoints;

  var lessThan = function lessThan(breakpoint) {
    return function () {
      return Ae(_templateObject(), getSizeFromBreakpoint(breakpoint, breakpoints), Ae.apply(void 0, arguments));
    };
  };

  var greaterThan = function greaterThan(breakpoint) {
    return function () {
      return Ae(_templateObject2(), getSizeFromBreakpoint(breakpoint, breakpoints), Ae.apply(void 0, arguments));
    };
  };

  var between = function between(firstBreakpoint, secondBreakpoint) {
    return function () {
      return Ae(_templateObject3(), getSizeFromBreakpoint(firstBreakpoint, breakpoints), getSizeFromBreakpoint(secondBreakpoint, breakpoints), Ae.apply(void 0, arguments));
    };
  };

  var oldStyle = Object.keys(breakpoints).reduce(function (acc, label) {
    var size = breakpoints[label];

    acc.to[label] = function () {
      console.warn("styled-media-query: Use media.lessThan('".concat(label, "') instead of old media.to.").concat(label, " (Probably we'll deprecate it)"));
      return Ae(_templateObject4(), size, Ae.apply(void 0, arguments));
    };

    acc.from[label] = function () {
      console.warn("styled-media-query: Use media.greaterThan('".concat(label, "') instead of old media.from.").concat(label, " (Probably we'll deprecate it)"));
      return Ae(_templateObject5(), size, Ae.apply(void 0, arguments));
    };

    return acc;
  }, {
    to: {},
    from: {}
  });
  return Object.assign({
    lessThan: lessThan,
    greaterThan: greaterThan,
    between: between
  }, oldStyle);
}
/**
 * Media object with default breakpoints
 * @return {object} - Media generators for each size
 */


var index = generateMedia();
export default index;