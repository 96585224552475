import _defineProperty from "@babel/runtime/helpers/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { AnimatePresence, AnimateSharedLayout, motion } from "../../../_snowpack/pkg/framer-motion.js";
import React, { useEffect } from "../../../_snowpack/pkg/react.js";
import { authAnimation, elementAnimation, loadingAnimation, logoAnimation } from "../../ui/animations/auth.js";
import { AuthPage } from "./Auth.style.js";
import Button from "../../components/Button/Button.js";
import { DollarCircle } from "../../ui/icons.js";
import GoogleScript from "../../components/GoogleScript/GoogleScript.js";
import Input from "../../components/Form/Input/Input.js";
import { Link } from "../../../_snowpack/pkg/react-router-dom.js";
import Loader from "../../components/Loader/Loader.js";
import Page from "../../components/Page/Page.js";
import { ReactTitle } from "../../../_snowpack/pkg/react-meta-tags.js";
import { useForm } from "../../../_snowpack/pkg/react-hook-form.js";
import { useStore } from "../../datalayer/store.js";

var LoginPageComponent = function LoginPageComponent(props) {
  var loginState = useStore(function (state) {
    return state.loginState;
  });
  var loginService = useStore(function (state) {
    return state.loginService;
  });
  var setLoginState = useStore(function (state) {
    return state.setLoginState;
  });
  var setLoginService = useStore(function (state) {
    return state.setLoginService;
  });
  var auth = useStore(function (state) {
    return state.actions.auth;
  });

  var _useForm = useForm({
    mode: "onBlur"
  }),
      register = _useForm.register,
      handleSubmit = _useForm.handleSubmit,
      getValues = _useForm.getValues,
      errors = _useForm.formState.errors;

  var signupWithGoogle = function signupWithGoogle() {
    auth.signupWithGoogle();
  };

  var signupWithEmail = function signupWithEmail(_ref) {
    var email = _ref.email;
    auth.signupWithEmail(email);
  };

  useEffect(function () {
    return function () {
      setLoginState("login");
      setLoginService(void 0);
    };
  }, []);
  return /* @__PURE__ */React.createElement(Page, {
    backgroundImage: "/images/pattern.png"
  }, /* @__PURE__ */React.createElement(ReactTitle, {
    title: "Log in"
  }), /* @__PURE__ */React.createElement(AuthPage, null, /* @__PURE__ */React.createElement(AnimateSharedLayout, null, /* @__PURE__ */React.createElement("div", {
    className: "center-layout"
  }, /* @__PURE__ */React.createElement(motion.div, {
    className: "cevoid-logo",
    variants: logoAnimation,
    initial: "hidden",
    animate: "show",
    exit: "hidden",
    layout: true
  }, loginState === "loading" ? /* @__PURE__ */React.createElement(Loader, null) : /* @__PURE__ */React.createElement(DollarCircle, null)), /* @__PURE__ */React.createElement("div", {
    className: "form-wrapper"
  }, /* @__PURE__ */React.createElement(AnimatePresence, {
    exitBeforeEnter: true
  }, loginState === "login" && /* @__PURE__ */React.createElement(motion.div, {
    key: "login",
    className: "login-state",
    variants: authAnimation,
    initial: "hidden",
    animate: "show",
    exit: "hidden"
  }, /* @__PURE__ */React.createElement(motion.h2, {
    variants: elementAnimation
  }, "Login to Cevoid Referrals"), loginService === void 0 && /* @__PURE__ */React.createElement("div", {
    className: "button-group"
  }, /* @__PURE__ */React.createElement(Button, {
    key: "continue-with-google",
    color: "primary",
    expanded: true,
    onClick: signupWithGoogle,
    variants: elementAnimation
  }, "Continue with Google"), /* @__PURE__ */React.createElement(Button, {
    key: "continue-email",
    color: "theme",
    expanded: true,
    onClick: function onClick() {
      return setLoginService("email");
    },
    variants: elementAnimation
  }, "Continue with email")), /* @__PURE__ */React.createElement(motion.div, {
    key: "disclaimer",
    className: "disclaimer",
    variants: elementAnimation
  }, "You acknowledge that you have read, and agree to our", " ", /* @__PURE__ */React.createElement(Link, {
    to: "/partner-terms-agreement",
    target: "_blank",
    rel: "noreferrer noopener"
  }, "Partner Terms Agreement"), " ", "."), loginService === "email" && /* @__PURE__ */React.createElement("form", {
    onSubmit: handleSubmit(signupWithEmail)
  }, /* @__PURE__ */React.createElement("div", {
    className: "button-group"
  }, /* @__PURE__ */React.createElement(Input, _objectSpread(_objectSpread({}, register("email", {
    required: true,
    pattern: /\S+@\S+\.\S+/
  })), {}, {
    key: "email",
    id: "email",
    placeholder: "Enter your email...",
    error: errors.email,
    errorMessage: "Invalid email",
    variants: elementAnimation
  })), /* @__PURE__ */React.createElement(Button, {
    key: "login-email",
    color: "primary",
    expanded: true,
    type: "submit",
    variants: elementAnimation
  }, "Login")))), loginState === "loading" && /* @__PURE__ */React.createElement(motion.div, {
    key: "loading",
    className: "loading-state",
    variants: loadingAnimation,
    initial: "hidden",
    animate: "show",
    exit: "hidden"
  }, /* @__PURE__ */React.createElement(motion.h2, {
    key: "1",
    variants: elementAnimation
  }, "Check your email"), /* @__PURE__ */React.createElement(motion.div, {
    key: "2",
    variants: elementAnimation,
    className: "email-sent-to"
  }, "An email has been sent to"), /* @__PURE__ */React.createElement(motion.div, {
    key: "3",
    variants: elementAnimation,
    className: "email"
  }, getValues().email), /* @__PURE__ */React.createElement(motion.div, {
    key: "4",
    variants: elementAnimation,
    className: "click-link"
  }, "Click the link in the email to log in"))))))), /* @__PURE__ */React.createElement(GoogleScript, null));
};

export default LoginPageComponent;