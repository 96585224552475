import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";
var _excluded = ["id", "defaultValue", "placeholder", "type", "error", "errorMessage", "onChange", "inputRef", "disabled"];

var _templateObject;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import styled, { css } from "../../../../_snowpack/pkg/styled-components.js";
import Error from "../Error/Error.js";
import React from "../../../../_snowpack/pkg/react.js";
import { motion } from "../../../../_snowpack/pkg/framer-motion.js";
export var Input = styled(motion.div)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  width: 100%;\n  position: relative;\n\n  input {\n    background: var(--background);\n    color: var(--text);\n    border: 1px solid var(--border-strong);\n    padding: 10px 15px;\n    border-radius: var(--border-radius);\n    width: 100%;\n    outline: none;\n    transition: 0.15s border var(--transition);\n    font-size: var(--font-xs);\n\n    &:focus {\n      border: 1px solid var(--primary);\n    }\n\n    &:disabled {\n      background-color: var(--element);\n      border: none;\n      opacity: 0.7;\n    }\n  }\n"])));
var InputComponent = React.forwardRef(function (_ref, ref) {
  var id = _ref.id,
      defaultValue = _ref.defaultValue,
      placeholder = _ref.placeholder,
      type = _ref.type,
      error = _ref.error,
      errorMessage = _ref.errorMessage,
      onChange = _ref.onChange,
      inputRef = _ref.inputRef,
      disabled = _ref.disabled,
      props = _objectWithoutProperties(_ref, _excluded);

  return /* @__PURE__ */React.createElement(Input, _objectSpread({}, props), /* @__PURE__ */React.createElement("input", _objectSpread({
    id: id,
    name: id,
    defaultValue: defaultValue,
    placeholder: placeholder,
    type: type,
    onChange: onChange,
    ref: ref,
    autoComplete: "off",
    spellCheck: "false",
    disabled: disabled
  }, props)), /* @__PURE__ */React.createElement(Error, {
    error: error,
    message: errorMessage
  }));
});
export default InputComponent;