import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";
var _excluded = ["type", "size"];

var _templateObject;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import styled, { css } from "../../../_snowpack/pkg/styled-components.js";
import { CircleLoader } from "../../ui/icons.js";
import PropTypes from "../../../_snowpack/pkg/prop-types.js";
import React from "../../../_snowpack/pkg/react.js";
export var Loader = styled.div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  svg {\n    color: var(--text);\n  }\n"])));

var LoaderComponent = function LoaderComponent(_ref) {
  var type = _ref.type,
      size = _ref.size,
      props = _objectWithoutProperties(_ref, _excluded);

  return /* @__PURE__ */React.createElement(Loader, _objectSpread({
    type: type,
    size: size
  }, props), /* @__PURE__ */React.createElement(CircleLoader, null));
};

LoaderComponent.propTypes = {
  type: PropTypes.oneOf(["circle"]),
  size: PropTypes.oneOf(["small", "medium", "large"])
};
LoaderComponent.defaultProps = {
  type: "circle",
  size: "medium"
};
export default LoaderComponent;