import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { r as react } from './common/index-404563d3.js';
import './common/_commonjsHelpers-798ad6a7.js';

function create$1(createState) {
  var state;
  var listeners = new Set();

  var setState = function setState(partial, replace) {
    var nextState = typeof partial === "function" ? partial(state) : partial;

    if (nextState !== state) {
      var previousState = state;
      state = replace ? nextState : Object.assign({}, state, nextState);
      listeners.forEach(function (listener) {
        return listener(state, previousState);
      });
    }
  };

  var getState = function getState() {
    return state;
  };

  var subscribeWithSelector = function subscribeWithSelector(listener) {
    var selector = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : getState;
    var equalityFn = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : Object.is;
    var currentSlice = selector(state);

    function listenerToAdd() {
      var nextSlice = selector(state);

      if (!equalityFn(currentSlice, nextSlice)) {
        var previousSlice = currentSlice;
        listener(currentSlice = nextSlice, previousSlice);
      }
    }

    listeners.add(listenerToAdd);
    return function () {
      return listeners["delete"](listenerToAdd);
    };
  };

  var subscribe = function subscribe(listener, selector, equalityFn) {
    if (selector || equalityFn) {
      return subscribeWithSelector(listener, selector, equalityFn);
    }

    listeners.add(listener);
    return function () {
      return listeners["delete"](listener);
    };
  };

  var destroy = function destroy() {
    return listeners.clear();
  };

  var api = {
    setState: setState,
    getState: getState,
    subscribe: subscribe,
    destroy: destroy
  };
  state = createState(setState, getState, api);
  return api;
}

var isSSR = typeof window === "undefined" || !window.navigator || /ServerSideRendering|^Deno\//.test(window.navigator.userAgent);
var useIsomorphicLayoutEffect = isSSR ? react.useEffect : react.useLayoutEffect;

function create(createState) {
  var api = typeof createState === "function" ? create$1(createState) : createState;

  var useStore = function useStore() {
    var selector = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : api.getState;
    var equalityFn = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : Object.is;

    var _react$useReducer = react.useReducer(function (c) {
      return c + 1;
    }, 0),
        _react$useReducer2 = _slicedToArray(_react$useReducer, 2),
        forceUpdate = _react$useReducer2[1];

    var state = api.getState();
    var stateRef = react.useRef(state);
    var selectorRef = react.useRef(selector);
    var equalityFnRef = react.useRef(equalityFn);
    var erroredRef = react.useRef(false);
    var currentSliceRef = react.useRef();

    if (currentSliceRef.current === void 0) {
      currentSliceRef.current = selector(state);
    }

    var newStateSlice;
    var hasNewStateSlice = false;

    if (stateRef.current !== state || selectorRef.current !== selector || equalityFnRef.current !== equalityFn || erroredRef.current) {
      newStateSlice = selector(state);
      hasNewStateSlice = !equalityFn(currentSliceRef.current, newStateSlice);
    }

    useIsomorphicLayoutEffect(function () {
      if (hasNewStateSlice) {
        currentSliceRef.current = newStateSlice;
      }

      stateRef.current = state;
      selectorRef.current = selector;
      equalityFnRef.current = equalityFn;
      erroredRef.current = false;
    });
    var stateBeforeSubscriptionRef = react.useRef(state);
    useIsomorphicLayoutEffect(function () {
      var listener = function listener() {
        try {
          var nextState = api.getState();
          var nextStateSlice = selectorRef.current(nextState);

          if (!equalityFnRef.current(currentSliceRef.current, nextStateSlice)) {
            stateRef.current = nextState;
            currentSliceRef.current = nextStateSlice;
            forceUpdate();
          }
        } catch (error) {
          erroredRef.current = true;
          forceUpdate();
        }
      };

      var unsubscribe = api.subscribe(listener);

      if (api.getState() !== stateBeforeSubscriptionRef.current) {
        listener();
      }

      return unsubscribe;
    }, []);
    return hasNewStateSlice ? newStateSlice : currentSliceRef.current;
  };

  Object.assign(useStore, api);

  useStore[Symbol.iterator] = function () {
    console.warn("[useStore, api] = create() is deprecated and will be removed in v4");
    var items = [useStore, api];
    return {
      next: function next() {
        var done = items.length <= 0;
        return {
          value: items.shift(),
          done: done
        };
      }
    };
  };

  return useStore;
}

export default create;